<template>
  <div class="page-article" v-if="detail">
    <div class="header">
      <div class="header-title" v-html="formatBr(detail.policyFileName)"></div>
      <div class="source">
        <span class="source-item time">{{ onlineTime }}</span>
        <span class="source-item cmpy-name"
          >来源：{{ detail.publishAgency }}</span
        >
      </div>
    </div>
    <div class="content">
      <div v-html="detail.content" id="article"></div>
      <!-- <iframe
        style="width: 100%"
        id="article"
        frameborder="0"
        :style="{ height: iframeHeight + 'rem' }"
      ></iframe> -->
      <!-- <iframe :srcdoc="detail.content" frameborder="0"></iframe> -->
    </div>
    <div class="files" v-if="files.length">
      <h3>附件:</h3>
      <div>
        <div
          :key="item.objectKey"
          v-for="item in files"
          style="margin: 0.1rem 0"
        >
          <span class="file-link" @click="downLoad(item)">
            {{ item.fileName }}
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="pageview">阅读量：{{ pageview }}</div> -->
    <div class="share">
      <!-- <div class="read">阅读量：{{ pageview }}</div> -->
      <div class="divider">
        <div class="divider-text">END</div>
      </div>
      <div class="share-gzh">点击<go-wechat-public />,了解更多人才资讯</div>
      <img style="max-width: 100%" src="@/assets/share.jpg" alt="" />
    </div>

    <back-top
      @click="handleTop"
      target=".page-article"
      :bottom="100"
    ></back-top>
  </div>
</template>

<script>
import { getNewsDetail, queryNewsPV } from "@/api/policy/news.js";
import { downFromUrl } from "@/utils";
import wxApiConfig from "@/utils/wxApiConfig";
import BackTop from "@/components/BackTop/index.vue";
import GoWechatPublic from "@/components/GoWechatPublic";

export default {
  data() {
    const panes = [
      {
        status: "5",
        count: 0,
        label: "在线",
      },
      {
        status: "4",
        label: "审核通过",
        count: 0,
      },
      {
        status: "1",
        count: 0,
        label: "审核中",
      },

      {
        status: "3",
        count: 0,
        label: "已驳回",
      },
      {
        status: "2",
        count: 0,
        label: "已撤回",
      },
      {
        status: "6",
        count: 0,
        label: "已下线",
      },

      {
        status: "0",
        label: "草稿",
        count: 0,
      },
    ];
    return {
      useModules: ["talent"],
      detail: "",
      id: "",
      files: [],
      iframeHeight: 0,
      pageview: 0,
      onlineTime: "",
    };
  },
  components: { BackTop, GoWechatPublic },
  mounted() {
    const { id } = this.$route.params;
    if (!id) return;
    this.id = id;
    if (this.isPc()) {
      window.location.href =
        process.env.VUE_APP_BASE_ORIGIN +
        "/#/backstage/backstageHome/detail/" +
        this.id;
    }
    this.getDetail();
    // this.getPageviews();
  },
  methods: {
    /* 回到顶部 */
    handleTop(e) {
      // console.log(e);
    },
    // \n 转 br
    formatBr(text) {
      if (text) {
        return text.replace(/\n/g, "<br/>");
      } else {
        return text;
      }
    },
    //获取协议
    getDetail() {
      getNewsDetail({ idCode: this.id }).then((res) => {
        console.log(res.data);
        this.detail = res.data;
        // 未上线，显示更新时间
        this.onlineTime = Object.prototype.hasOwnProperty.call(
          this.detail,
          "onlineTime"
        )
          ? this.detail.onlineTime.split(" ")[0]
          : this.detail.updateTime.split(" ")[0];

        if (res.data.fileUrl) {
          this.files = Object.values(res.data.fileUrl);
        }
        wxApiConfig.getConfig(
          this.detail.policyFileName,
          "河南郑州航空港人才办"
        );
        this.$nextTick(() => {
          this.previewImage();
        });
      });
    },
    // 判断是否为PC访问
    isPc() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },

    downLoad(e) {
      downFromUrl(e.url);
    },
    //iframe 保证样式隔离
    viewArticle() {
      const b = document.getElementById("article").contentWindow.document.body;
      b.style.margin = "0";
      b.style.overflow = "hidden";
      b.innerHTML = this.detail.content;
      setInterval(() => {
        //防止内容加载过慢导致高度不正确
        this.iframeHeight = b.offsetHeight;
      }, 200);
    },
    previewImage() {
      let imgs = document.getElementsByTagName("img");
      let imgSrcs = [];
      for (let index = 0; index < imgs.length; index++) {
        const element = imgs[index];
        imgSrcs.push(element.src);
      }
      for (let index = 0; index < imgs.length; index++) {
        const element = imgs[index];
        element.onclick = function () {
          wxApiConfig.wxPreviewImage(element.src, imgSrcs);
        };
      }
    },
    getPageviews() {
      queryNewsPV({
        newsCodes: this.id,
      }).then((res) => {
        this.pageview = res.data[0].pvCount;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-article {
  padding: 0.3rem;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
}
.header {
  margin-top: 0.18rem;
  .header-title {
    text-align: center;
    font-size: 0.44rem;
    margin-top: 0.18rem;
    font-weight: bold;
    color: #115abb;
  }
  .source {
    font-size: 0.28rem;
    margin: 0.24rem 0;
    color: #777;
    text-align: center;
    .source-item {
      display: inline-block;
      margin-right: 0.2rem;
      margin-bottom: 0.2rem;
    }
    .cmpy-name {
      display: inline-block;
    }
    .time {
      color: #777;
    }
  }
}

.file-link {
  word-break: break-all;
  color: #409eff;
}

.pageview {
  display: flex;
  justify-content: space-between;
  font-size: 0.24rem;
  color: #999999;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.share {
  text-align: center;
  margin-bottom: 0.3rem;
}

.read {
  text-align: left;
  color: #909399;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.share-gzh {
  display: flex;
  margin: 0 0 0.2rem 0;
  align-items: baseline;
  color: #666;
  font-size: 0.26rem;
  justify-content: right;
  .guide-contain {
    margin: 0 0.1rem;
  }
}
.divider {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  background-color: #0170c1;
  position: relative;
}
.divider-text {
  position: absolute;
  background-color: #f9f9f9;
  padding: 0 20px;
  font-weight: 500;
  color: #0170c1;
  font-size: 14px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
