
<template>
  <div class="guide-contain">
    <div @click="goPublic" class="go-public" id="goPublic">
      河南郑州航空港人才
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  props: {},
  methods: {
    goPublic() {
      let ua = navigator.userAgent.toLowerCase();
      let isWXWork = ua.match(/wxwork/i) == "wxwork";
      let isWeixin =
        !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
      if (isWeixin) {
        window.location.href =
          "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwMjQxNTI4Mw==#wechat_redirect";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.guide-contain {
  display: inline-block;
  .go-public {
    display: inline-block;
    font-size: 0.3rem;
    color: #576b95;
    cursor: pointer;
  }
}
</style>
